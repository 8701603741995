<template>
    <div
        class="mywallet-wrapper"
        v-if="userInfo && userInfo.info"
    >
        <!--头部统计-->
        <section class="mywallet-nth1 mt-0">
            <article class="assets-item">
                <ul class="wasset-list">
                    <li>
                        <p class="w-numb yellow">
                            <span>￥</span>
                            <span>{{userInfo.info.total | numberFormat(2)}}</span>
                        </p>
                        <!-- <span class="w-unit">元</span> -->
                        <h5 class="w-name">总资金</h5>
                    </li>

                    <li>
                        <p class="w-numb">
                            <span>{{userInfo.info.points_surplus}}</span>
                        </p>
                        <!-- <span class="w-unit">点券</span> -->
                        <h5 class="w-name">点券余额</h5>
                        <p class="w-contrl">
                            <a
                                href="javascript:void(0)"
                                @click="tabClick({index:3})"
                            >明细</a>
                            <a
                                href="javascript:void(0)"
                                @click="buyCoupon"
                            >购买</a>
                        </p>
                    </li>
                </ul>
            </article>

            <article class="assets-item">
                <ul
                    class="wasset-list wasset-listnth2"
                    :class="[userInfo.info.user_type==1?'wasset-listnth2':'wasset-listnth3']"
                >
                    <li>
                        <p class="w-numb">
                            <span>￥</span>
                            <span>{{ userInfo.info.surplus | numberFormat(2)}}</span>
                        </p>
                        <!-- <span class="w-unit">元(人民币)</span> -->
                        <h5 class="w-name">账户余额（可提现）</h5>
                        <p class="w-contrl">
                            <a
                                href="javascript:void(0)"
                                @click="recharge"
                            >充值</a>
                            <a
                                href="javascript:void(0)"
                                @click="withdraw"
                            >提现</a>
                        </p>
                    </li>

                    <li v-if="userInfo.info.user_type==1">
                        <p class="w-numb">
                            <span>￥</span>
                            <span>{{userInfo.info.reward | numberFormat(2)}}</span>
                        </p>
                        <!-- <span class="w-unit">元(人民币)</span> -->
                        <h5 class="w-name">已赚取酬金</h5>
                    </li>
                    <li>
                        <p
                            class="w-numb"
                            v-if="userInfo.info.user_type==1"
                        >
                            <span>￥</span>
                            <span>{{userInfo.info.bond | numberFormat(2)}}</span>
                        </p>
                        <p
                            class="w-numb"
                            v-if="userInfo.info.user_type==2"
                        >
                            <span>￥</span>
                            <span>{{userInfo.info.remuneration | numberFormat(2)}}</span>
                        </p>
                        <!-- <span class="w-unit">元(人民币)</span> -->
                        <h5
                            class="w-name"
                            v-if="userInfo.info.user_type==1"
                        >托管中履约保证金</h5>
                        <h5
                            class="w-name"
                            v-else
                        >托管中酬金</h5>
                        <p class="w-contrl">
                            <a
                                href="javascript:void(0)"
                                @click="showTging"
                            >明细</a>
                        </p>
                    </li>

                    <li>
                        <p class="w-numb yellow">
                            <span>￥</span>
                            <span>{{userInfo.info.commission | numberFormat(2)}}</span>
                        </p>
                        <!-- <span class="w-unit">元(人民币)</span> -->
                        <h5 class="w-name">已赚取佣金</h5>
                        <p class="w-contrl">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="邀请好友，赚取佣金"
                                placement="bottom"
                            >
                                <a
                                    href="javascript:void(0)"
                                    @click="goCommission"
                                >邀请码和明细</a>
                            </el-tooltip>
                        </p>
                    </li>
                </ul>
            </article>

            <!-- <article class="fpacks-item">
                <p class="packs-txt">
                    <span v-if="userInfo.info.user_type==1">
                        <span>项目报名次数：</span>
                        <span class="frequency">{{userInfo.info.apply_numbers}}次</span>
                        <a href="javascript:void(0)" @click="tabClick({index:4})">明细</a>
                    </span>
                </p>
            </article>-->
        </section>

        <!--明细-->
        <div class="mywallet-nth2">
            <!--tabs菜单按钮组-->
            <div
                class="wallettabs-wrap"
                style="margin-top: -15px;"
            >
                <!--雇主-->
                <el-tabs
                    v-model="activeName"
                    v-if="userInfo.info.user_type==2"
                    @tab-click="tabClick"
                >
                    <el-tab-pane
                        label="账单"
                        name="1"
                    ></el-tab-pane>
                    <el-tab-pane
                        label="余额明细"
                        name="2"
                    ></el-tab-pane>
                    <el-tab-pane
                        label="托管酬金明细"
                        name="3"
                    ></el-tab-pane>
                    <el-tab-pane
                        label="点券明细"
                        name="4"
                    ></el-tab-pane>
                </el-tabs>

                <!--工程极客-->
                <el-tabs
                    v-model="activeName"
                    v-else
                    @tab-click="tabClick"
                >
                    <el-tab-pane
                        label="账单"
                        name="1"
                    ></el-tab-pane>
                    <el-tab-pane
                        label="余额明细"
                        name="2"
                    ></el-tab-pane>
                    <el-tab-pane
                        label="托管履约保证金明细"
                        name="3"
                    ></el-tab-pane>
                    <el-tab-pane
                        label="点券明细"
                        name="4"
                    ></el-tab-pane>
                    <!-- <el-tab-pane label="报名次数明细" name="5"></el-tab-pane> -->
                </el-tabs>
            </div>

            <div
                class="ticket-box"
                v-loading="loading"
            >
                <!--1.账单-->
                <div
                    class="ticket-nth1"
                    v-if="activeName == 1"
                >
                    <div class="ticket-form">
                        <el-form
                            :inline="true"
                            class="demo-form-inline"
                        >
                            <!--雇主-->
                            <el-dropdown
                                style="float:left;"
                                trigger="click"
                                v-if="userInfo.info.user_type==2"
                                @command="dropdownClick"
                            >
                                <el-button type="primary">
                                    筛选明细
                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                </el-button>

                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="1">充值</el-dropdown-item>
                                    <el-dropdown-item command="2">获得佣金</el-dropdown-item>
                                    <el-dropdown-item command="3">余额托管酬金</el-dropdown-item>
                                    <el-dropdown-item command="4">余额托管酬金失败、返回</el-dropdown-item>
                                    <el-dropdown-item command="5">在线支付托管酬金</el-dropdown-item>
                                    <el-dropdown-item command="6">在线支付托管酬金失败、返回</el-dropdown-item>
                                    <el-dropdown-item command="7">支付酬金</el-dropdown-item>
                                    <el-dropdown-item command="8">退回托管酬金</el-dropdown-item>
                                    <el-dropdown-item command="9">支付争议托管的酬金</el-dropdown-item>
                                    <el-dropdown-item command="10">退回争议托管的酬金</el-dropdown-item>
                                    <el-dropdown-item command="11">获得争议保证金</el-dropdown-item>
                                    <el-dropdown-item command="12">余额购点券</el-dropdown-item>
                                    <el-dropdown-item command="13">在线支付购买点券</el-dropdown-item>
                                    <el-dropdown-item command="14">获得点券</el-dropdown-item>
                                    <el-dropdown-item command="15">点券支付</el-dropdown-item>
                                    <el-dropdown-item command="16">提现</el-dropdown-item>
                                    <el-dropdown-item command="17">对公帐号途径入帐</el-dropdown-item>
                                    <el-dropdown-item command="18">热门项目</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>

                            <!--极客-->
                            <el-dropdown
                                style="float:left;"
                                trigger="click"
                                v-if="userInfo.info.user_type==1"
                                @command="dropdownClick"
                            >
                                <el-button type="primary">
                                    筛选明细
                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                </el-button>

                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="1">充值</el-dropdown-item>
                                    <el-dropdown-item command="2">获得佣金</el-dropdown-item>
                                    <el-dropdown-item command="3">余额托管保证金</el-dropdown-item>
                                    <el-dropdown-item command="4">余额托管保证金失败、返回</el-dropdown-item>
                                    <el-dropdown-item command="5">在线支付托管保证金</el-dropdown-item>
                                    <el-dropdown-item command="6">在线支付托管保证金失败、返回</el-dropdown-item>
                                    <el-dropdown-item command="7">获得酬金</el-dropdown-item>
                                    <el-dropdown-item command="8">退回托管的保证金</el-dropdown-item>
                                    <el-dropdown-item command="9">支付争议保证金</el-dropdown-item>
                                    <el-dropdown-item command="10">退回争议保证金</el-dropdown-item>
                                    <el-dropdown-item command="11">获得争议酬金</el-dropdown-item>
                                    <el-dropdown-item command="12">余额购点券</el-dropdown-item>
                                    <el-dropdown-item command="13">在线支付购买点券</el-dropdown-item>
                                    <el-dropdown-item command="14">获得点券</el-dropdown-item>
                                    <el-dropdown-item command="15">点券支付</el-dropdown-item>
                                    <el-dropdown-item command="16">提现</el-dropdown-item>
                                    <el-dropdown-item command="17">对公帐号途径入帐</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>

                            <el-form-item label="选择时间">
                                <el-date-picker
                                    v-model="date"
                                    type="daterange"
                                    align="right"
                                    unlink-panels
                                    range-separator="-"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :picker-options="pickerOptions"
                                    @change="changeDate"
                                ></el-date-picker>
                            </el-form-item>
                        </el-form>
                    </div>

                    <div
                        class="ticket-nth2"
                        v-if="payLogList.length>0"
                    >
                        <ul class="ticket-list">
                            <li
                                class="ticket-item"
                                v-for="item in payLogList"
                                :key="item.id"
                            >
                                <div class="left">
                                    <h4 class="tit-name">
                                        {{item.title}}
                                        <span v-if="item.status.key==2">(待审核)</span>
                                        <span v-if="item.status.key==0">(失败)</span>
                                    </h4>
                                    <p class="quan-statue">
                                        {{item.amount_cate.value}}
                                        <span v-if="item.amount_pay_type.value">- {{item.amount_pay_type.value}}</span>
                                    </p>
                                    <p class="t-time">{{item.create_time}}</p>
                                </div>

                                <div class="right">
                                    <p class="w-numb">
                                        <span>
                                            <!--极客-->
                                            <template v-if="userInfo.info.user_type==1">
                                                <template v-if="item.geek_bill==0">{{item.val | numberFormat(2)}}</template>
                                                <template v-else>
                                                    <span
                                                        v-if="item.geek_bill==1"
                                                        class="typeTag"
                                                    >+</span>
                                                    <span
                                                        v-else
                                                        class="typeTag"
                                                    >-</span>
                                                    {{item.val | numberFormat(2)}}
                                                </template>
                                            </template>

                                            <!--雇主-->
                                            <template v-else>
                                                <template v-if="item.geek_bill==0">{{item.val | numberFormat(2)}}</template>
                                                <template v-else>
                                                    <span
                                                        v-if="item.geek_bill==1"
                                                        class="typeTag"
                                                    >+</span>
                                                    <span
                                                        v-else
                                                        class="typeTag"
                                                    >-</span>
                                                    {{item.val | numberFormat(2)}}
                                                </template>
                                            </template>
                                        </span>

                                        <i class="chief">元</i>
                                    </p>

                                    <div class="w-lik-wrap">
                                        <a
                                            href="javascript:void(0)"
                                            class="lkd-link"
                                            @click="payDetail(item)"
                                        >查看详情</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div
                        v-else
                        class="emptyData"
                    >暂无相关数据</div>
                </div>

                <!--2.余额明细-->
                <div
                    class="ticket-nth1"
                    v-if="activeName == 2"
                >
                    <div
                        class="ticket-nth2"
                        v-if="payLogList.length>0"
                    >
                        <ul class="ticket-list">
                            <li
                                class="ticket-item"
                                v-for="item in payLogList"
                                :key="item.id"
                            >
                                <div class="left">
                                    <h4 class="tit-name">
                                        {{item.title}}
                                        <span v-if="item.status.key==2">(待审核)</span>
                                    </h4>

                                    <p class="quan-statue">
                                        {{item.amount_cate.value}}
                                        <span v-if="item.amount_pay_type.value">- {{item.amount_pay_type.value}}</span>
                                    </p>
                                    <p class="t-time">{{item.create_time}}</p>
                                </div>

                                <div class="right">
                                    <p class="w-numb">
                                        <span>
                                            <!--极客-->
                                            <template v-if="userInfo.info.user_type==1">
                                                <template v-if="item.geek_balance==0">{{item.val | numberFormat(2)}}</template>
                                                <template v-else>
                                                    <span
                                                        v-if="item.geek_balance==1"
                                                        class="typeTag"
                                                    >+</span>
                                                    <span
                                                        v-else
                                                        class="typeTag"
                                                    >-</span>
                                                    {{item.val | numberFormat(2)}}
                                                </template>
                                            </template>

                                            <!--雇主-->
                                            <template v-else>
                                                <template v-if="item.employer_balance==0">{{item.val | numberFormat(2)}}</template>
                                                <template v-else>
                                                    <span
                                                        v-if="item.employer_balance==1"
                                                        class="typeTag"
                                                    >+</span>
                                                    <span
                                                        v-else
                                                        class="typeTag"
                                                    >-</span>
                                                    {{item.val | numberFormat(2)}}
                                                </template>
                                            </template>
                                        </span>

                                        <i class="chief">元</i>
                                    </p>

                                    <div class="w-lik-wrap">
                                        <a
                                            href="javascript:void(0)"
                                            class="lkd-link"
                                            @click="payDetail_balance(item)"
                                        >查看详情</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div
                        v-else
                        class="emptyData"
                    >暂无相关数据</div>
                </div>

                <!--3.托管履约保证金/酬金明细-->
                <div
                    class="aticket-wrap"
                    v-if="activeName == 3"
                >
                    <div
                        class="ticket-nth3"
                        v-if="payLogList.length>0"
                    >
                        <ul class="ticket-list">
                            <li
                                class="ticket-item"
                                v-for="(item,index) in payLogList"
                                :key="index"
                            >
                                <div class="left">
                                    <h4 class="tit-name">
                                        {{item.title}}
                                        <span v-if="item.status.key==2">(待审核)</span>
                                    </h4>
                                    <p class="quan-statue">
                                        {{item.amount_cate.value}}
                                        <span v-if="item.amount_pay_type.value">- {{item.amount_pay_type.value}}</span>
                                    </p>
                                    <p class="t-time">{{item.create_time}}</p>
                                </div>

                                <div class="right">
                                    <p class="w-numb">
                                        <span>
                                            <!--极客-->
                                            <template v-if="userInfo.info.user_type==1">
                                                <template v-if="item.geek_trust==0">{{item.val | numberFormat(2)}}</template>
                                                <template v-else>
                                                    <span
                                                        v-if="item.geek_trust==1"
                                                        class="typeTag"
                                                    >+</span>
                                                    <span
                                                        v-else
                                                        class="typeTag"
                                                    >-</span>
                                                    {{item.val | numberFormat(2)}}
                                                </template>
                                            </template>

                                            <!--雇主-->
                                            <template v-else>
                                                <template v-if="item.employer_trust==0">{{item.val | numberFormat(2)}}</template>
                                                <template v-else>
                                                    <span
                                                        v-if="item.employer_trust==1"
                                                        class="typeTag"
                                                    >+</span>
                                                    <span
                                                        v-else
                                                        class="typeTag"
                                                    >-</span>
                                                    {{item.val | numberFormat(2)}}
                                                </template>
                                            </template>
                                        </span>
                                        <i class="chief">元</i>
                                    </p>

                                    <div class="w-lik-wrap">
                                        <a
                                            href="javascript:void(0)"
                                            class="lkd-link"
                                            @click="payDetail_trust(item)"
                                        >查看详情</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div
                        v-else
                        class="emptyData"
                    >暂无相关数据</div>
                </div>

                <!--4.点券明细-->
                <div
                    class="aticket-wrap"
                    v-if="activeName == 4"
                >
                    <div
                        class="ticket-nth3"
                        v-if="payLogList.length>0"
                    >
                        <ul class="ticket-list">
                            <li
                                class="ticket-item"
                                v-for="(item,index) in payLogList"
                                :key="index"
                            >
                                <div class="left">
                                    <h4 class="tit-name">{{item.title}}</h4>
                                    <p
                                        class="quan-statue"
                                        :class="{'warn':item.type.key === 2}"
                                    >{{item.remark}}</p>
                                    <p class="t-time">{{item.create_time}}</p>
                                </div>
                                <div class="right">
                                    <p class="w-numb w-doct-numb">
                                        <span>
                                            <!--极客-->
                                            <template v-if="userInfo.info.user_type==1">
                                                <template v-if="item.geek_point!=0">
                                                    <span
                                                        v-if="item.geek_point==1"
                                                        class="typeTag2"
                                                    >+</span>
                                                    <span
                                                        v-else
                                                        class="typeTag2"
                                                    >-</span>
                                                </template>
                                            </template>

                                            <!--雇主-->
                                            <template v-else>
                                                <template item.employer_point!="0">
                                                    <span
                                                        v-if="item.employer_point==1"
                                                        class="typeTag2"
                                                    >+</span>
                                                    <span
                                                        v-else
                                                        class="typeTag2"
                                                    >-</span>
                                                </template>
                                            </template>
                                            {{item.val}}
                                        </span>
                                    </p>
                                    <div class="w-lik-wrap">
                                        <a
                                            href="javascript:void(0)"
                                            class="lkd-link"
                                            @click="payDetail_point(item)"
                                        >查看详情</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div
                        v-else-if="payLogList.length == 0 && currentPage == 1 "
                        class="emptyData"
                    >暂无相关数据</div>
                </div>

                <!--7.报名次数明细-->
                <!-- <div class="aticket-wrap"
                     v-if="activeName == 5">
                    <div class="ticket-nth3"
                         v-if="payLogList.length>0">
                        <ul class="ticket-list">
                            <li class="ticket-item"
                                v-for="(item,index) in payLogList"
                                :key="index">
                                <div class="left">
                                    <h4 class="tit-name">{{item.title}}</h4>
                                    <p class="quan-statue"
                                       :class="{'warn':item.type.key === 2}">{{item.remark}}</p>
                                    <p class="t-time">{{item.create_time}}</p>
                                </div>
                                <div class="right">
                                    <p class="w-numb w-doct-numb"
                                       >
                                        <span>
                                            <span v-if="item.type.key==1"
                                                  class="typeTag2">+</span>
                                            <span v-else
                                                  class="typeTag2">-</span>
                                            {{item.val}}
                                        </span>次
                                    </p>
                                    <div class="w-lik-wrap">
                                        <a href="javascript:void(0)"
                                           class="lkd-link"
                                           @click="payDetail(item)">查看详情</a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div v-else-if="payLogList.length == 0 && currentPage == 1 "
                         class="emptyData">暂无相关数据</div>
                </div>-->

                <pagination
                    v-if="payLogList.length >0"
                    :pageSize="pageSize"
                    :currentPage="currentPage"
                    :pageTotal="pageTotal"
                    @current-change="newPage"
                ></pagination>
            </div>
        </div>

        <!--账单详情-->
        <el-dialog
            title="账单详情"
            :visible="detailsDialog"
            width="640px"
            center
            @close="closeall"
        >
            <div class="wallet-popups">
                <p
                    class="price"
                    :class="{'red':log_detail.type === 2}"
                >
                    <template v-if="1==userInfo.info.user_type">
                        <strong v-if="0==log_detail.geek_bill">{{log_detail.val}}</strong>
                        <strong v-if="1==log_detail.geek_bill">+{{log_detail.val}}</strong>
                        <strong v-if="2==log_detail.geek_bill">-{{log_detail.val}}</strong>
                    </template>
                    <template v-else>
                        <strong v-if="0==log_detail.employer_bill">{{log_detail.val}}</strong>
                        <strong v-if="1==log_detail.employer_bill">+{{log_detail.val}}</strong>
                        <strong v-if="2==log_detail.employer_bill">-{{log_detail.val}}</strong>
                    </template>
                </p>

                <template v-if="log_detail.amount_cate && 2==log_detail.amount_cate.key">
                    <span
                        class="status"
                        v-if="log_detail.status && 0==log_detail.status.key"
                        style="color:#9b001c"
                    >提现失败</span>

                    <span
                        class="status"
                        v-if="log_detail.status && 1==log_detail.status.key"
                        style="color:#75b900"
                    >提现成功</span>

                    <span
                        class="status"
                        v-if="log_detail.status && 2==log_detail.status.key"
                        style="color:#75b900"
                    >提现中</span>
                </template>

                <template v-else>
                    <span
                        class="status"
                        v-if="log_detail.status && 1==log_detail.status.key"
                        style="color:#75b900"
                    >交易成功</span>
                </template>

                <div class="wallet-bd">
                    <div class="wallet-msg">
                        <el-row>
                            <el-col :span="4">创建时间：</el-col>
                            <el-col :span="20">{{log_detail.create_time}}</el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="4">交易单号：</el-col>
                            <el-col :span="20">{{log_detail.order_no}}</el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="4">订单类型：</el-col>
                            <el-col :span="20">{{log_detail.title}}</el-col>
                        </el-row>
                    </div>

                    <div class="wallet-msg">
                        <el-row v-if="log_detail.pay_type">
                            <el-col :span="4">交易渠道：</el-col>
                            <el-col
                                :span="20"
                                v-text="log_detail.pay_type"
                            ></el-col>
                        </el-row>

                        <el-row v-if="log_detail.account">
                            <el-col :span="4">交易账号：</el-col>
                            <el-col
                                :span="20"
                                v-text="log_detail.account"
                            ></el-col>
                        </el-row>

                        <el-row v-if="log_detail.account_name">
                            <el-col :span="4">开户行：</el-col>
                            <el-col
                                :span="20"
                                v-text="log_detail.account_name"
                            ></el-col>
                        </el-row>

                        <el-row v-if="log_detail.amount_pay_no">
                            <el-col :span="4">流水号：</el-col>
                            <el-col
                                :span="20"
                                v-text="log_detail.amount_pay_no"
                            ></el-col>
                        </el-row>

                        <el-row v-if="log_detail.task_sn">
                            <el-col
                                :span="4"
                                style="#9b001c"
                            >项目编号：</el-col>
                            <el-col
                                :span="20"
                                style="#9b001c"
                                v-text="log_detail.task_sn"
                            ></el-col>
                        </el-row>

                        <el-row v-if="log_detail.task_name">
                            <el-col
                                :span="4"
                                style="#9b001c"
                            >项目名称：</el-col>
                            <el-col
                                :span="20"
                                style="#9b001c"
                                v-text="log_detail.task_name"
                            ></el-col>
                        </el-row>

                        <el-row v-if="log_detail._geek_id">
                            <el-col :span="4">对方ID：</el-col>
                            <el-col
                                :span="20"
                                v-text="log_detail._geek_id"
                            ></el-col>
                        </el-row>
                    </div>
                </div>
            </div>

            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button @click="closeall">返回</el-button>
            </span>
        </el-dialog>

        <!--余额详情-->
        <el-dialog
            title="余额详情"
            :visible="detailsDialog_balance"
            width="640px"
            center
            @close="closeall"
        >
            <div class="wallet-popups">
                <p
                    class="price"
                    :class="{'red':log_detail_balance.type === 2}"
                >
                    <template v-if="1==userInfo.info.user_type">
                        <strong v-if="0==log_detail_balance.geek_balance">{{log_detail_balance.val}}</strong>
                        <strong v-if="1==log_detail_balance.geek_balance">+{{log_detail_balance.val}}</strong>
                        <strong v-if="2==log_detail_balance.geek_balance">-{{log_detail_balance.val}}</strong>
                    </template>
                    <template v-else>
                        <strong v-if="0==log_detail_balance.employer_balance">{{log_detail_balance.val}}</strong>
                        <strong v-if="1==log_detail_balance.employer_balance">+{{log_detail_balance.val}}</strong>
                        <strong v-if="2==log_detail_balance.employer_balance">-{{log_detail_balance.val}}</strong>
                    </template>
                </p>

                <template v-if="log_detail_balance.amount_cate && 2==log_detail_balance.amount_cate.key">
                    <span
                        class="status"
                        v-if="log_detail_balance.status && 0==log_detail_balance.status.key"
                        style="color:#9b001c"
                    >提现失败</span>

                    <span
                        class="status"
                        v-if="log_detail_balance.status && 1==log_detail_balance.status.key"
                        style="color:#75b900"
                    >提现成功</span>

                    <span
                        class="status"
                        v-if="log_detail_balance.status && 2==log_detail_balance.status.key"
                        style="color:#75b900"
                    >提现中</span>
                </template>
                <template v-else>
                    <span
                        class="status"
                        v-if="log_detail_balance.status && 1==log_detail_balance.status.key"
                        style="color:#75b900"
                    >交易成功</span>
                </template>

                <div class="wallet-bd">
                    <div class="wallet-msg">
                        <el-row>
                            <el-col :span="4">创建时间：</el-col>
                            <el-col :span="20">{{log_detail_balance.create_time}}</el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="4">交易单号：</el-col>
                            <el-col :span="20">{{log_detail_balance.order_no}}</el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="4">订单类型：</el-col>
                            <el-col :span="20">{{log_detail_balance.title}}</el-col>
                        </el-row>
                    </div>

                    <div class="wallet-msg">
                        <el-row v-if="log_detail_balance.pay_type">
                            <el-col :span="4">交易渠道：</el-col>
                            <el-col
                                :span="20"
                                v-text="log_detail_balance.pay_type"
                            ></el-col>
                        </el-row>

                        <el-row v-if="log_detail_balance.account">
                            <el-col :span="4">交易账号：</el-col>
                            <el-col
                                :span="20"
                                v-text="log_detail_balance.account"
                            ></el-col>
                        </el-row>

                        <el-row v-if="log_detail_balance.account_name">
                            <el-col :span="4">开户行：</el-col>
                            <el-col
                                :span="20"
                                v-text="log_detail_balance.account_name"
                            ></el-col>
                        </el-row>

                        <el-row v-if="log_detail_balance.amount_pay_no">
                            <el-col :span="4">流水号：</el-col>
                            <el-col
                                :span="20"
                                v-text="log_detail_balance.amount_pay_no"
                            ></el-col>
                        </el-row>

                        <el-row v-if="log_detail_balance._geek_id">
                            <el-col :span="4">对方ID：</el-col>
                            <el-col
                                :span="20"
                                v-text="log_detail_balance._geek_id"
                            ></el-col>
                        </el-row>
                    </div>
                </div>
            </div>

            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button @click="closeall">返回</el-button>
            </span>
        </el-dialog>

        <!--托管详情-->
        <el-dialog
            title="托管详情"
            :visible="detailsDialog_trust"
            width="640px"
            center
            @close="closeall"
        >
            <div class="wallet-popups">
                <p
                    class="price"
                    :class="{'red':log_detail_trust.type === 2}"
                >
                    <template v-if="1==userInfo.info.user_type">
                        <strong v-if="0==log_detail_trust.geek_trust">{{log_detail_trust.val}}</strong>
                        <strong v-if="1==log_detail_trust.geek_trust">+{{log_detail_trust.val}}</strong>
                        <strong v-if="2==log_detail_trust.geek_trust">-{{log_detail_trust.val}}</strong>
                    </template>
                    <template v-else>
                        <strong v-if="0==log_detail_trust.employer_trust">{{log_detail_trust.val}}</strong>
                        <strong v-if="1==log_detail_trust.employer_trust">+{{log_detail_trust.val}}</strong>
                        <strong v-if="2==log_detail_trust.employer_trust">-{{log_detail_trust.val}}</strong>
                    </template>
                </p>

                <template v-if="log_detail_trust.amount_cate && 2==log_detail_trust.amount_cate.key">
                    <span
                        class="status"
                        v-if="log_detail_trust.status && 0==log_detail_trust.status.key"
                        style="color:#9b001c"
                    >提现失败</span>

                    <span
                        class="status"
                        v-if="log_detail_trust.status && 1==log_detail_trust.status.key"
                        style="color:#75b900"
                    >提现成功</span>

                    <span
                        class="status"
                        v-if="log_detail_trust.status && 2==log_detail_trust.status.key"
                        style="color:#75b900"
                    >提现中</span>
                </template>
                <template v-else>
                    <span
                        class="status"
                        v-if="log_detail_trust.status && 1==log_detail_trust.status.key"
                        style="color:#75b900"
                    >交易成功</span>
                </template>

                <div class="wallet-bd">
                    <div class="wallet-msg">
                        <el-row>
                            <el-col :span="4">创建时间：</el-col>
                            <el-col :span="20">{{log_detail_trust.create_time}}</el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="4">交易单号：</el-col>
                            <el-col :span="20">{{log_detail_trust.order_no}}</el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="4">订单类型：</el-col>
                            <el-col :span="20">{{log_detail_trust.title}}</el-col>
                        </el-row>
                    </div>

                    <div class="wallet-msg">
                        <el-row v-if="log_detail_trust.pay_type">
                            <el-col :span="4">交易渠道：</el-col>
                            <el-col
                                :span="20"
                                v-text="log_detail_trust.pay_type"
                            ></el-col>
                        </el-row>

                        <el-row v-if="log_detail_trust.account">
                            <el-col :span="4">交易账号：</el-col>
                            <el-col
                                :span="20"
                                v-text="log_detail_trust.account"
                            ></el-col>
                        </el-row>

                        <el-row v-if="log_detail_trust.account_name">
                            <el-col :span="4">开户行：</el-col>
                            <el-col
                                :span="20"
                                v-text="log_detail_trust.account_name"
                            ></el-col>
                        </el-row>

                        <el-row v-if="log_detail_trust.amount_pay_no">
                            <el-col :span="4">流水号：</el-col>
                            <el-col
                                :span="20"
                                v-text="log_detail_trust.amount_pay_no"
                            ></el-col>
                        </el-row>

                        <el-row v-if="log_detail_trust.task_sn">
                            <el-col
                                :span="4"
                                style="#9b001c"
                            >项目编号：</el-col>
                            <el-col
                                :span="20"
                                style="#9b001c"
                                v-text="log_detail_trust.task_sn"
                            ></el-col>
                        </el-row>

                        <el-row v-if="log_detail_trust.task_name">
                            <el-col
                                :span="4"
                                style="#9b001c"
                            >项目名称：</el-col>
                            <el-col
                                :span="20"
                                style="#9b001c"
                                v-text="log_detail_trust.task_name"
                            ></el-col>
                        </el-row>

                        <el-row v-if="log_detail_trust._geek_id">
                            <el-col :span="4">对方ID：</el-col>
                            <el-col
                                :span="20"
                                v-text="log_detail_trust._geek_id"
                            ></el-col>
                        </el-row>
                    </div>
                </div>
            </div>

            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button @click="closeall">返回</el-button>
            </span>
        </el-dialog>

        <!--点券详情-->
        <el-dialog
            title="点券详情"
            :visible="detailsDialog_point"
            width="640px"
            center
            @close="closeall"
        >
            <div class="wallet-popups">
                <p
                    class="price"
                    :class="{'red':log_detail_point.type === 2}"
                >
                    <template v-if="1==userInfo.info.user_type">
                        <strong v-if="0==log_detail_point.geek_point">{{log_detail_point.val}}</strong>
                        <strong v-if="1==log_detail_point.geek_point">+{{log_detail_point.val}}</strong>
                        <strong v-if="2==log_detail_point.geek_point">-{{log_detail_point.val}}</strong>
                    </template>
                    <template v-else>
                        <strong v-if="0==log_detail_point.employer_point">{{log_detail_point.val}}</strong>
                        <strong v-if="1==log_detail_point.employer_point">+{{log_detail_point.val}}</strong>
                        <strong v-if="2==log_detail_point.employer_point">-{{log_detail_point.val}}</strong>
                    </template>
                </p>

                <template v-if="log_detail_point.amount_cate && 2==log_detail_point.amount_cate.key">
                    <span
                        class="status"
                        v-if="log_detail_point.status && 0==log_detail_point.status.key"
                        style="color:#9b001c"
                    >提现失败</span>

                    <span
                        class="status"
                        v-if="log_detail_point.status && 1==log_detail_point.status.key"
                        style="color:#75b900"
                    >提现成功</span>

                    <span
                        class="status"
                        v-if="log_detail_point.status && 2==log_detail_point.status.key"
                        style="color:#75b900"
                    >提现中</span>
                </template>
                <template v-else>
                    <span
                        class="status"
                        v-if="log_detail_point.status && 1==log_detail_point.status.key"
                        style="color:#75b900"
                    >交易成功</span>
                </template>

                <div class="wallet-bd">
                    <div class="wallet-msg">
                        <el-row>
                            <el-col :span="4">创建时间：</el-col>
                            <el-col :span="20">{{log_detail_point.create_time}}</el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="4">交易单号：</el-col>
                            <el-col :span="20">{{log_detail_point.order_no}}</el-col>
                        </el-row>

                        <el-row>
                            <el-col :span="4">订单类型：</el-col>
                            <el-col :span="20">{{log_detail_point.title}}</el-col>
                        </el-row>
                    </div>

                    <div class="wallet-msg">
                        <el-row v-if="log_detail_point.pay_type">
                            <el-col :span="4">交易渠道：</el-col>
                            <el-col
                                :span="20"
                                v-text="log_detail_point.pay_type"
                            ></el-col>
                        </el-row>

                        <el-row v-if="log_detail_point.account">
                            <el-col :span="4">交易账号：</el-col>
                            <el-col
                                :span="20"
                                v-text="log_detail_point.account"
                            ></el-col>
                        </el-row>

                        <el-row v-if="log_detail_point.account_name">
                            <el-col :span="4">开户行：</el-col>
                            <el-col
                                :span="20"
                                v-text="log_detail_point.account_name"
                            ></el-col>
                        </el-row>

                        <el-row v-if="log_detail_point.amount_pay_no">
                            <el-col :span="4">流水号：</el-col>
                            <el-col
                                :span="20"
                                v-text="log_detail_point.amount_pay_no"
                            ></el-col>
                        </el-row>

                        <el-row
                            v-if="log_detail_point.article_sn"
                            style="margin-top:10px;"
                        >
                            <el-col
                                :span="4"
                                style="#9b001c"
                            >资料编号：</el-col>
                            <el-col
                                :span="20"
                                style="#9b001c"
                                v-text="log_detail_point.article_sn"
                            ></el-col>
                        </el-row>

                        <el-row v-if="log_detail_point.article_name">
                            <el-col
                                :span="4"
                                style="#9b001c"
                            >资料名称：</el-col>
                            <el-col
                                :span="20"
                                style="#9b001c"
                                v-text="log_detail_point.article_name"
                            ></el-col>
                        </el-row>

                        <el-row
                            v-if="log_detail_point.task_sn"
                            style="margin-top:10px;"
                        >
                            <el-col
                                :span="4"
                                style="#9b001c"
                            >项目编号：</el-col>
                            <el-col
                                :span="20"
                                style="#9b001c"
                                v-text="log_detail_point.task_sn"
                            ></el-col>
                        </el-row>

                        <el-row v-if="log_detail_point.task_name">
                            <el-col
                                :span="4"
                                style="#9b001c"
                            >项目名称：</el-col>
                            <el-col
                                :span="20"
                                style="#9b001c"
                                v-text="log_detail_point.task_name"
                            ></el-col>
                        </el-row>

                        <el-row v-if="log_detail_point._geek_id">
                            <el-col :span="4">对方ID：</el-col>
                            <el-col
                                :span="20"
                                v-text="log_detail_point._geek_id"
                            ></el-col>
                        </el-row>
                    </div>
                </div>
            </div>

            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button @click="closeall">返回</el-button>
            </span>
        </el-dialog>

        <!--托管中酬金明细-->
        <el-dialog
            :title="userInfo.info.user_type==2?'托管中的酬金明细':'托管中履约保证金明细'"
            :visible.sync="tgz_cj"
            width="1000px"
            center
            class="blue-top-border topay-dialog"
            @close="closeall"
        >
            <!--6.托管中酬金明细-->
            <div
                class="aticket-wrap"
                v-if="userInfo.info.user_type==2"
            >
                <el-table
                    :data="bondRemunerationList"
                    v-if="bondRemunerationList && bondRemunerationList.length>0"
                    style="width: 100%"
                >
                    <el-table-column
                        prop="id"
                        label="项目编号"
                        width="180"
                    ></el-table-column>
                    <el-table-column
                        prop="name"
                        label="项目名称"
                    ></el-table-column>
                    <el-table-column
                        prop="price"
                        width="120"
                        label="托管总酬金"
                    ></el-table-column>
                    <el-table-column
                        prop="price_pay"
                        width="120"
                        label="已支付酬金"
                    ></el-table-column>
                    <el-table-column
                        prop="price_waiting"
                        width="120"
                        label="托管中酬金"
                    ></el-table-column>
                    <el-table-column
                        prop="time"
                        width="180"
                        label="托管时间"
                    ></el-table-column>
                </el-table>
                <div
                    v-else
                    class="emptyData"
                >暂无相关数据</div>
            </div>

            <!--6.托管中履约保证金明细-->
            <div
                class="aticket-wrap"
                v-if="userInfo.info.user_type==1"
            >
                <el-table
                    :data="bondRemunerationList"
                    v-if="bondRemunerationList && bondRemunerationList.length>0"
                    style="width: 100%"
                >
                    <el-table-column
                        prop="id"
                        label="项目编号"
                        width="180"
                    ></el-table-column>

                    <el-table-column
                        prop="name"
                        label="项目名称"
                    ></el-table-column>

                    <el-table-column
                        prop="price"
                        width="160"
                        label="托管履约保证金"
                    ></el-table-column>

                    <el-table-column
                        prop="time"
                        width="180"
                        label="托管时间"
                    ></el-table-column>
                </el-table>

                <div
                    v-else
                    class="emptyData"
                >暂无相关数据</div>
            </div>
        </el-dialog>

        <!--充值-->
        <el-dialog
            title="充值"
            :visible.sync="rechargeDialog"
            width="750px"
            height="530px"
            center
            class="blue-top-border topay-dialog czbox"
            :close-on-click-modal="false"
            @close="closeall"
            v-loading="loading"
        >
            <form class="topays-bd">
                <ul class="topays-list">
                    <li class="topays-item item1">
                        <span class="ite-lab">请选择充值方式</span>
                        <el-radio-group v-model="czRadio">
                            <div class="setbank-wrap">
                                <el-row>
                                    <el-col :span="24">
                                        <el-radio :label="3">
                                            <i class="jk-icon-weixinzhifu1"></i>
                                            <span class="icon-text">使用微信支付进行充值</span>
                                        </el-radio>
                                    </el-col>
                                </el-row>

                                <el-row>
                                    <el-col :span="24">
                                        <el-radio :label="4">
                                            <i class="jk-icon-zhifubao1"></i>使用支付宝支付进行充值
                                        </el-radio>
                                    </el-col>
                                </el-row>

                                <el-row>
                                    <el-col :span="24">
                                        <el-radio :label="5">
                                            <i class="jk-icon-yinlian"></i>
                                            <span>通过银行卡转账充值</span>
                                        </el-radio>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-radio-group>
                    </li>

                    <li class="topays-item withdraw-input-wrap">
                        <el-row style="border:none;">
                            <el-col :span="24">
                                <span style="float:left;">当前账户余额：{{userInfo.info.surplus}} 元</span>
                                <span
                                    style="float:right;cursor:pointer"
                                    @click="goHelp(152)"
                                >
                                    <el-image
                                        style="width:22px;height:22px;float:left;margin-top:4px;"
                                        :src="$img.question_icon"
                                    ></el-image>
                                    <span style="float:left;display:inline-block;line-height:30px;margin-left:6px;">点击这里，了解有关充值及余额使用的问题</span>
                                </span>
                            </el-col>
                        </el-row>
                    </li>

                    <li
                        class="topays-item withdraw-input-wrap item2"
                        v-if="czRadio!=5"
                    >
                        <span class="ite-lab">请输入充值金额</span>
                        <el-row>
                            <el-col
                                :span="2"
                                style="text-align:right;"
                            >人民币</el-col>
                            <el-col
                                :span="10"
                                style="margin-left:10px;margin-right:10px;border:1px solid #ddd;background:#fff;border-radius:4px;"
                            >
                                <el-input
                                    placeholder="输入充值金额"
                                    v-model="rechargePrice"
                                    onkeyup="value=value.match(/\d+\.?\d{0,2}/,'')"
                                    auto-complete="new-money"
                                ></el-input>
                            </el-col>
                            <el-col :span="4">元</el-col>
                        </el-row>
                    </li>

                    <li class="topays-item item3">
                        <el-button
                            v-if="czRadio!=5"
                            class="withdraw-btn"
                            @click="rechargeBtn"
                        >充值</el-button>
                        <el-button
                            v-if="czRadio==5"
                            class="withdraw-btn"
                            @click="czNext"
                        >下一步</el-button>
                    </li>
                </ul>
            </form>
        </el-dialog>

        <!--提现-->
        <el-dialog
            title="提现"
            :visible.sync="withdrawDialog"
            width="750px"
            center
            class="blue-top-border topay-dialog czbox"
            :close-on-click-modal="false"
            v-loading="loading"
        >
            <form class="topays-bd">
                <ul class="topays-list">
                    <li class="topays-item item1">
                        <span class="ite-lab">提现到</span>
                        <el-radio-group
                            v-model="txRadio"
                            @change="changeTxRadio"
                        >
                            <div class="setbank-wrap">
                                <!--银行卡提现-->
                                <el-row>
                                    <el-col :span="24">
                                        <el-radio :label="2">
                                            <span>
                                                <i class="jk-icon-yinlian"></i>
                                                <span>银行卡</span>
                                            </span>
                                            <span v-if="txRadio==2">
                                                &nbsp;&nbsp;
                                                <img
                                                    class="wbak-icon"
                                                    :src="$img.baom"
                                                />
                                                <span>
                                                    <el-select
                                                        class="bak-selt"
                                                        v-model="bankSelect"
                                                        filterable
                                                        allow-create
                                                        default-first-option
                                                        placeholder="请选择银行卡"
                                                        @change="BankChange"
                                                        style="padding-bottom:20px;"
                                                    >
                                                        <el-option
                                                            v-for="item in bankList"
                                                            :key="item.id"
                                                            :label="item | bankFilter"
                                                            :value="item.id"
                                                        >
                                                            {{item | bankFilter}}
                                                            <router-link :to="'/user/wallet/my_bankcard?id='+item.id">查看</router-link>
                                                        </el-option>

                                                        <el-option
                                                            label
                                                            value
                                                        >
                                                            <el-button
                                                                class="add-bank-btn"
                                                                @click="addBackBtn(1)"
                                                                style="padding:0"
                                                            >
                                                                <i class="jk-icon-tianjia"></i>
                                                                添加银行卡
                                                            </el-button>
                                                        </el-option>
                                                    </el-select>
                                                </span>
                                            </span>
                                        </el-radio>
                                    </el-col>
                                </el-row>

                                <!--支付宝提现-->
                                <el-row>
                                    <el-col :span="24">
                                        <el-radio :label="4">
                                            <span>
                                                <i class="jk-icon-zhifubao1"></i>
                                                <span>支付宝</span>
                                            </span>
                                            <span v-if="txRadio==4">
                                                <el-input
                                                    v-focus="txRadio==4"
                                                    style="margin-left:15px;"
                                                    v-model="withdrawAlipay"
                                                    placeholder="输入本人提现支付宝账户"
                                                    class="withdrawalipay"
                                                ></el-input>
                                            </span>
                                        </el-radio>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-radio-group>
                    </li>

                    <li style="line-height:30px;">
                        <el-row style="margin-top:10px;">
                            <el-col :span="24">
                                <span style="float:left;">
                                    可提现金额：{{userInfo.info.surplus}}元
                                    <em
                                        class="blue"
                                        style="cursor:pointer"
                                        @click="allWithdraw"
                                    >全部提现</em>
                                </span>

                                <span
                                    style="float:right;cursor:pointer"
                                    @click="goHelp(151)"
                                >
                                    <el-image
                                        style="width:22px;height:22px;float:left;margin-top:4px;"
                                        :src="$img.question_icon"
                                    ></el-image>
                                    <span style="float:left;display:inline-block;line-height:30px;margin-left:6px;">点击这里，了解更多有关提现的问题</span>
                                </span>
                            </el-col>
                        </el-row>
                    </li>

                    <li
                        class="topays-item withdraw-input-wrap item2 tx_center_box"
                        style="margin-top:10px;padding-top:20px;"
                    >
                        <span class="ite-lab">请输入提现金额</span>
                        <el-row style="margin-top:10px;">
                            <el-col
                                :span="2"
                                style="padding-top: 4px;"
                            >人民币</el-col>
                            <el-col :span="10">
                                <el-input
                                    style="border:1px solid #ddd;background:#fff;border-radius:4px;"
                                    placeholder="输入提现金额"
                                    v-model="withdrawPrice"
                                    onkeyup="value=value.match(/\d+\.?\d{0,2}/,'')"
                                    @blur="withdraw_input_blur"
                                ></el-input>
                            </el-col>
                            <el-col
                                :span="2"
                                style="margin-left:10px;"
                            >元</el-col>
                            <el-col
                                :span="6"
                                style="margin-left:10px;color:red;"
                            >{{tx_error_tip}}</el-col>
                        </el-row>

                        <el-row>
                            <span style="font-size:14px;color:#999;margin-left:52px;">最低提现金额：{{userInfo.config.lowest_amount}} 元，收取1%手续费</span>
                        </el-row>

                        <el-row>
                            <div class="desc_box">
                                <div class="titer">
                                    <el-image
                                        class="icon"
                                        :src="$img.icon_gg"
                                    ></el-image>
                                    <span class="text">提现规则：</span>
                                </div>
                                <div class="con">
                                    <ul>
                                        <li>1、每个用户每天只能提现1次。</li>
                                        <li>2、每次最低提现100元。</li>
                                        <li>3、个人帐户每个月每个帐户最多提现20万元。</li>
                                    </ul>
                                </div>

                                <div class="titer">
                                    <el-image
                                        class="icon"
                                        :src="$img.icon_clock"
                                    ></el-image>
                                    <span class="text">到帐时间：</span>
                                </div>
                                <div class="con">
                                    <ul>
                                        <li>您在工作日提交的提现申请，到账时间根据各家银行规定为准，一般1~2个工作日到账。</li>
                                        <li>注意：每天晚8点后的提现算作第二天申请，节假日申请不做处理，到账时间顺延。如发生特殊情况，请及时与询龙网联系。</li>
                                    </ul>
                                </div>
                            </div>
                        </el-row>
                    </li>

                    <li
                        class="topays-item"
                        style="margin-top:10px;"
                    >
                        <div style="width:100%;text-align:center;line-height:30px;">今日剩余提现次数：{{today_withdraw_times}} 次</div>

                        <div style="width:100%;line-height:40px;height:40px;text-align:center;">
                            <div style="display:inline-block">
                                <el-image
                                    style="width:20px;height:20px;margin-top:10px;float:left;"
                                    :src="$img.icon_jth"
                                ></el-image>
                                <span style="float:left;margin-left:6px;color:#587DDC;">仅提现成功会扣除提现次数，如您提交的提现申请失败，则提现次数会返还。</span>
                            </div>
                        </div>
                        <el-button
                            class="withdraw-btn"
                            @click="withdrawBtn"
                        >提现</el-button>
                    </li>
                </ul>
            </form>
        </el-dialog>

        <!--提现 - 确认框-->
        <el-dialog
            title="提现信息确认"
            :visible.sync="withdrawDialog_confirm"
            width="450px"
            center
            class="blue-top-border topay-dialog czbox"
            @close="closeall"
            :close-on-click-modal="false"
            v-loading="loading"
        >
            <div class="confirm">
                <div class="item">
                    <form class="topays-bd">
                        <ul class="topays-list">
                            <li class="topays-item item1">
                                <span class="ite-lab">提现到</span>
                                <el-radio-group v-model="czRadio">
                                    <div class="setbank-wrap">
                                        <el-row>
                                            <el-col
                                                :span="24"
                                                v-if="txRadio==2"
                                            >
                                                <el-radio
                                                    :label="3"
                                                    class="is-checked"
                                                    disabled
                                                    style="text-align:center;cursor:normal;"
                                                >
                                                    <i class="jk-icon-yinlian"></i>
                                                    <span
                                                        class="icon-text"
                                                        style="color:#587ddc;"
                                                    >银行卡</span>
                                                </el-radio>

                                                <el-radio
                                                    :label="3"
                                                    disabled
                                                >
                                                    <span style="color:#587ddc;">银行卡卡号：{{show_account}}</span>
                                                </el-radio>
                                            </el-col>

                                            <el-col
                                                :span="24"
                                                v-if="txRadio==4"
                                            >
                                                <el-radio
                                                    :label="3"
                                                    class="is-checked"
                                                    disabled
                                                    style="text-align:center;cursor:normal;"
                                                >
                                                    <i class="jk-icon-zhifubao1"></i>
                                                    <span
                                                        class="icon-text"
                                                        style="color:#587ddc;"
                                                    >支付宝</span>
                                                </el-radio>

                                                <el-radio
                                                    :label="3"
                                                    disabled
                                                >
                                                    <span style="color:#587ddc;">支付宝账号：{{show_account}}</span>
                                                </el-radio>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </el-radio-group>
                            </li>

                            <li
                                class="item1"
                                style="margin-top:20px;"
                            >
                                <div style="letter-spacing:1px;">提现金额：{{tx_price1}} 元</div>
                                <div style="margin-top:4px;">
                                    <span>手</span>
                                    <span style="margin-left:4px;">续</span>
                                    <span style="margin-left:5px;">费：{{tx_price2}} 元</span>
                                </div>
                                <div style="letter-spacing:1px;margin-top:4px;">实际到账：{{tx_price3}} 元</div>
                            </li>

                            <li
                                class="topays-item item2"
                                style="margin-top:20px;padding-bottom:20px;"
                            >
                                <span class="ite-lab">请输入支付密码</span>
                                <el-row>
                                    <el-col :span="24">
                                        <el-input
                                            type="password"
                                            v-model="password"
                                        ></el-input>
                                    </el-col>
                                </el-row>
                            </li>

                            <li
                                class="topays-item"
                                style="margin-top:20px;"
                            >
                                <el-button
                                    class="withdraw-btn"
                                    @click="withdraw_submit"
                                >确认提现</el-button>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </el-dialog>

        <!--对公账户转账说明-->
        <el-dialog
            class="blue-top-border topay-dialog"
            title="银行卡转账充值说明"
            :visible.sync="dgzzDialog"
            width="830px"
            center
            @close="closeall"
            :close-on-click-modal="false"
            v-loading="loading"
        >
            <div class="dgzz-box">
                <div class="desc">若您需要通过银行卡转账充值余额，请汇款至询龙网对公汇款账号,并将汇款信息通过下方填写并提交。如有疑问，您也可以致电询龙网官方客服电话进行咨询：400-003-6700</div>

                <div class="dgzh">
                    <div class="title">汇款信息</div>
                    <ul class="list">
                        <li class="item">
                            <span class="name">开户名称：</span>
                            <span class="value">北京询龙云和科技有限公司</span>
                        </li>

                        <li class="item">
                            <span class="name">开户银行：</span>
                            <span class="value">招商银行股份有限公司北京首体支行</span>
                            <el-image
                                class="icon"
                                :src="websiteConfigs.sourceUrl+'/upload/image/zhaoshang_bank.jpg'"
                            ></el-image>
                        </li>

                        <li class="item">
                            <span class="name">汇款账号：</span>
                            <span class="value">110938383510701</span>
                        </li>
                    </ul>
                </div>

                <div class="zzxx">
                    <div class="title">如您已经完成了转账，请在下列框内输入您的汇款信息并提交</div>
                    <ul class="list">
                        <li class="item">
                            <div class="name">汇款人开户全称：</div>
                            <div class="value">
                                <el-input
                                    v-model="user_name"
                                    placeholder="请输入"
                                ></el-input>
                            </div>
                        </li>

                        <li class="item">
                            <div class="name">汇款开户账号：</div>
                            <div class="value">
                                <el-input
                                    v-model="user_no"
                                    type="number"
                                    placeholder="请输入"
                                ></el-input>
                            </div>
                        </li>

                        <li class="item">
                            <div class="name">汇款金额：</div>
                            <div class="value">
                                <el-input
                                    v-model="user_price"
                                    placeholder="请输入"
                                ></el-input>
                            </div>
                        </li>

                        <li class="item">
                            <div class="name">汇款人开户行：</div>
                            <div class="value">
                                <el-input
                                    v-model="user_bank"
                                    placeholder="请输入"
                                ></el-input>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="sure">
                    <div class="title">请您在转账完成后填写以上信息并提交，您的资金将在4个小时内到账，请注意查看您账户中的余额变化。</div>
                    <div class="btns">
                        <el-button
                            type="primary"
                            class="btn submit"
                            @click="submit_zz"
                        >提交</el-button>
                        <el-button
                            type="primray"
                            class="btn back"
                            @click="dgzzDialog=false;"
                        >返回</el-button>
                    </div>
                </div>
            </div>
        </el-dialog>

        <!--购买点券对话框-->
        <el-dialog
            class="blue-top-border topay-dialog"
            title="购买点券"
            :visible="buyCouponDialog"
            width="640px"
            center
            @close="closeall"
            :close-on-click-modal="false"
            v-loading="loading"
        >
            <form class="topays-bd">
                <ul class="topays-list">
                    <li class="topays-item">
                        <span class="ite-lab">选择购买点券</span>
                        <div class="setbank-wrap">
                            <el-radio-group
                                v-model="couponRadio"
                                class="buy-dialog-radio-group"
                                @change="selectCoupon"
                            >
                                <el-radio
                                    :label="item"
                                    v-for="item in couponList"
                                    :key="item.id"
                                >
                                    <div class="buy-group">
                                        <span>
                                            <strong>{{item.coupon}}</strong>点券
                                        </span>
                                        <p>￥{{item.price}}</p>
                                        <em class="guide">{{(item.price/item.coupon*100).toFixed(2)}}折</em>
                                    </div>
                                </el-radio>
                            </el-radio-group>
                        </div>
                    </li>
                    <li class="topays-item">
                        <span class="ite-lab">支付方式</span>
                        <el-radio-group
                            v-model="payTypeRadio"
                            @change="changePayTypeRadio"
                        >
                            <div class="setbank-wrap">
                                <el-row>
                                    <el-col :span="24">
                                        <el-radio :label="1">
                                            余额支付
                                            <span>（￥{{userInfo.info.surplus}}元）</span>
                                        </el-radio>
                                    </el-col>
                                </el-row>

                                <el-row>
                                    <el-col :span="24">
                                        <el-radio :label="3">
                                            <i class="jk-icon-weixinzhifu1"></i>微信
                                        </el-radio>
                                    </el-col>
                                </el-row>

                                <el-row>
                                    <el-col :span="24">
                                        <el-radio :label="4">
                                            <i class="jk-icon-zhifubao1"></i>支付宝
                                        </el-radio>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-radio-group>
                    </li>
                    <li
                        class="topays-item"
                        v-if="payTypeRadio == 1 || payTypeRadio == 2"
                    >
                        <span class="ite-lab">请输入支付密码</span>
                        <el-row>
                            <el-col :span="24">
                                <el-input
                                    type="password"
                                    v-model="password"
                                ></el-input>
                            </el-col>
                        </el-row>
                    </li>

                    <li class="topays-item">
                        <el-button
                            class="withdraw-btn"
                            @click="buyCouponBtn"
                        >购买</el-button>
                    </li>
                </ul>
            </form>
        </el-dialog>

        <!--设置密码对话框-->
        <el-dialog
            class="blue-top-border"
            title="设置密码对话框"
            :visible="setPayPasswordDialog"
            width="400px"
            center
            @close="closeall"
        >
            <div class="pointCoupon-buy">
                <h5>您还未设置支付密码，不能进行提现（充值），请先前往设置支付密码</h5>
            </div>
            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button @click="setPassword">去设置</el-button>
                <!--关闭之后跳转至账户安全设置支付密码-->
            </span>
        </el-dialog>

        <!--支付二维码-->
        <el-dialog
            class="blue-top-border"
            title="支付二维码"
            :visible="payQrDialog"
            width="500px"
            center
            @close="closePayQrDialog"
        >
            <div class="pointCoupon-buy">
                <p>支付金额</p>
                <p class="price">
                    <span>￥</span>
                    <strong>{{price}}</strong>（本次）
                </p>
                <div class="buy-ewm">
                    <img :src="util.reImg(QRImgUrl)" />
                </div>
                <div class="guide">
                    请使用{{payTypeText}}扫描
                    <br />二维码完成支付
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Pagination from "@/components/common/Pagination.vue";
export default {
    name: "myWallet",
    components: {
        Pagination //分页
    },
    computed: {
        ...mapState(["userInfo"]),
        ...mapState(["websitInfo"])
    },
    data() {
        return {
            buyCouponDialog: false, //购买点券对话框
            rechargeDialog: false, //充值对话框
            setPayPasswordDialog: false, //设置密码对话框
            payQrDialog: false, //微信支付二维码弹出框
            withdrawDialog: false, //提现对话框
            detailsDialog: false, //明细对话框
            detailsDialog_balance: false, //明细对话框
            detailsDialog_trust: false, //明细对话框
            detailsDialog_point: false, //明细对话框
            withdrawDialog_confirm: false,//提现确认框

            couponRadio: null, //点券选中项
            selectCouponPrice: 0, //选中的优惠券价格
            selectCouponNum: 0, //选中的点券数量
            payTypeRadio: 1, //购买点券 - 支付类型

            czRadio: 1, //充值radio默认值
            txRadio: 1, //提现radio默认值
            dgzzDialog: false, //对公转账说明对话框

            user_name: "", //对公账户转账 - 姓名
            user_no: "", //对公账户转账 - 账号
            user_price: "", //对公账户转账 - 金额
            user_bank: "", //对公账户转账 - 开户行

            password: "", //支付密码
            QRImgUrl: "", //支付二维码图片url
            payType: "", //支付方式
            payTypeText: "", //支付方式文字
            cate: 0, //订单分类
            title: "", //订单标题
            price: 0, //订单价格
            remark: "", //订单备注
            order_no: "", //订单编号
            rechargePrice: '', //充值金额
            withdrawPrice: '', //提现金额
            activeName: "1", //选项卡
            payDialog: false, //支付弹出框
            loading: false,
            timer: "",
            bankList: [], //银行卡列表
            payLogList: [], //明细列表
            couponList: [], //点券列表
            bankName: [], //银行卡列表
            log_detail: [], //明细详情
            log_detail_balance: [], //明细详情
            log_detail_trust: [], //明细详情
            log_detail_point: [], //明细详情
            bankSelect: "", //银行卡选择器
            withdrawAlipay: "", //提现支付宝
            date: "", //查询时间
            currentPage: 1,
            pageTotal: "",
            pageSize: 10,
            pickerOptions: {
                shortcuts: [
                    {
                        text: "最近一周",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 7
                            );
                            picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "最近一个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 30
                            );
                            picker.$emit("pick", [start, end]);
                        }
                    },
                    {
                        text: "最近三个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 90
                            );
                            picker.$emit("pick", [start, end]);
                        }
                    }
                ]
            },
            bondRemunerationList: {}, //托管中履约保证金明细/托管中酬金明细
            tabSelected: 0, //已经选择的tab
            tgz_cj: false, //托管中酬金对话框
            today_withdraw_times: 0,//今天提现次数
            show_account: '',//提现账号
            tx_price1: 0,//提现确认 - 提现金额
            tx_price2: 0,//提现确认 - 手续费
            tx_price3: 0,//提现确认 - 实际到账
            tx_error_tip: '',//提现错误提示
            selected_tx_account: '',//选择好的提现账号
        };
    },
    created(params) {
        //默认加载资金明细
        this.getPayList(1);

        //加载银行卡列表
        this.getBankList();

        if (this.$route.query.tx == 1) {
            this.withdrawDialog = true;
        }

        if (this.$route.query.buy_coupon == 1) {
            this.buyCoupon();
        }
    },
    filters: {
        bankFilter: val => {
            if (val) {
                return (
                    val.card_bank +
                    "  " +
                    "尾号" +
                    val.card_no.substr(val.card_no.length - 4) +
                    " " +
                    val.card_type
                );
            } else {
                return "";
            }
        }
    },
    methods: {
        ...mapActions("userInfo", ["ajaxUserInfo"]), //刷新用户数据方法

        //加载资金列表数据
        loadListData(type = 1, cate = 0) {
            let _this = this;
            _this.payLogList = [];
            // _this.loading = true;
            _this.post(
                "/log/log/lists",
                {
                    type: type, //tabs(1.账单；2余额；3.酬金/保证金；4.点券；5.下载次数)
                    cate: cate,
                    date: _this.date ? _this.date : 0, //时间筛选
                    page: _this.currentPage,
                    limit: _this.pageSize
                },
                function (res) {
                    _this.loading = false;
                    if (res.code == 200) {
                        _this.payLogList = res.data.list;
                        _this.pageTotal = res.data.count;
                    }
                }
            );
        },

        //加载托管中的履约保证金明细&托管中酬金明细
        loadRemunerationData() {
            let _this = this;
            _this.loading = true;
            _this.post(
                "/task/task/bonding_remunerationing_detail",
                {
                    page: _this.currentPage,
                    limit: _this.pageSize
                },
                function (res) {
                    _this.loading = false;
                    if (res.code == 200) {
                        _this.bondRemunerationList = res.data.list;
                        _this.pageTotal = res.data.count;
                    }
                }
            );
        },

        //点击tabs切换事件
        tabClick(val) {
            this.currentPage = 1; //切换到第一页
            this.tabSelected = parseInt(val.index) + 1; //tabs序号（1.账单；2余额；3.酬金/保证金；4.点券；5.下载次数）
            this.loadListData(this.tabSelected); //加载数据
            this.activeName = this.tabSelected.toString(); //切换到对应tabs
        },

        //下拉筛选 - 事件
        dropdownClick(v) {
            this.currentPage = 1; //切换到第一页
            this.loadListData(1, v); //加载数据
        },

        //显示托管中对话框
        showTging() {
            this.tgz_cj = true;
            this.loadRemunerationData();
        },

        //获得资金明细
        getPayList(type) {
            this.currentPage = 1;
            this.loadListData(type);
        },

        //账单 - 明细详情
        payDetail(item) {
            this.log_detail = item;
            this.detailsDialog = true;
        },

        //余额 - 明细详情
        payDetail_balance(item) {
            this.log_detail_balance = item;
            this.detailsDialog_balance = true;
        },

        //托管 - 明细详情
        payDetail_trust(item) {
            this.log_detail_trust = item;
            this.detailsDialog_trust = true;
        },

        //点券 - 明细详情
        payDetail_point(item) {
            this.log_detail_point = item;
            this.detailsDialog_point = true;
        },

        //时间查询
        changeDate() {
            this.getPayList();
        },

        //分页
        newPage(page) {
            let _this = this;
            _this.currentPage = page;
            _this.loadListData(this.tabSelected);
        },

        /**-------------------------- 购买点券 ------------------------- */

        //购买点券 - 购买点券按钮事件
        buyCoupon() {
            let _this = this;
            _this.price = 0;
            //判断是否设置了支付密码
            if (this.isSetPayPassword()) {
                //加载点券列表
                _this.post("/coupon/coupon/lists", {}, function (res) {
                    if (res.code == 200) {
                        _this.couponList = res.data;
                    }
                });

                //弹出点券列表购买[对话框]
                _this.buyCouponDialog = true;
            }
        },

        //购买点券 - 点券选中事件
        selectCoupon() {
            this.selectCouponPrice = this.couponRadio.price;
            this.selectCouponNum = this.couponRadio.coupon;
        },

        //购买点券 - 购买按钮事件
        buyCouponBtn() {
            let _this = this;
            if (_this.couponRadio == null) {
                _this.$message.error("请选择需要购买的点券");
                return false;
            }
            _this.QRImgUrl = ""; //清空支付二维码，防止网络慢显��上一次的
            _this.cate = 3; //购买点券
            _this.title = "购买点券";
            _this.price = _this.selectCouponPrice; //购买金额
            _this.remark = _this.selectCouponNum; //购买点券数
            _this.createOrder(1);
        },

        //购买点券 - 发送支付请求
        pay() {
            let _this = this;
            _this.post(
                "/pay/pay/pay",
                {
                    order_no: _this.order_no,
                    password: _this.password
                },
                function (res) {
                    if (res.code == 200) {
                        if (_this.payType == "D01" || _this.payType == "Y01") {
                            //点券或者余额支付
                            _this.loading = false;
                            _this.$message.success("恭喜你，支付成功");
                            setTimeout(() => {
                                _this.ajaxUserInfo(); //刷新我的钱包信息
                                _this.getPayList(); //刷新资金明细
                                _this.closeall(); //关闭对话框
                                return false;
                            }, 1000);

                            let currentPageUrl = sessionStorage.getItem(
                                "currentPageUrl"
                            );
                            if (currentPageUrl) {
                                sessionStorage.removeItem("currentPageUrl");
                                _this.$router.push(currentPageUrl);
                            }
                        } else {
                            //在线支付
                            _this.loading = false;
                            _this.price = res.data.price; //支付价格
                            _this.QRImgUrl = res.data.img; //显示支付二维码
                            _this.payQrDialog = true; //打开二维码支付对话框
                            _this.timer = setInterval(() => {
                                _this.isPay(); //定时查询订单状态
                            }, 2000);
                        }
                    } else {
                        _this.loading = false;
                        _this.$message.error(res.msg);
                        _this.$message.error(res.data.errmsg);
                    }
                    return false;
                }
            );
        },

        //购买点券 - 是否支付成功
        isPay() {
            let _this = this;
            this.post(
                "/pay/pay/is_pay",
                {
                    order_no: _this.order_no
                },
                function (res) {
                    if (res.code == 200) {
                        _this.$message.success("恭喜你，支付成功");
                        setTimeout(() => {
                            clearInterval(_this.timer); //清除定时器
                            _this.ajaxUserInfo(); //刷新我的钱包信息
                            _this.getPayList(); //刷新资金明细
                            _this.closeall(); //关闭所有对话框
                        }, 1000);
                        let currentPageUrl = sessionStorage.getItem(
                            "currentPageUrl"
                        );
                        if (currentPageUrl) {
                            sessionStorage.removeItem("currentPageUrl");
                            _this.$router.push(currentPageUrl);
                        }
                    }
                }
            );
        },

        //购买点券 - 支付方式选择
        changePayTypeRadio(val) {
            let _this = this;
            _this.password = ""; //清空密码
        },

        //关闭弹出框
        closeall() {
            //清空数据
            this.password = "";
            this.price = "";
            this.title = "";
            this.cate = "";
            this.remark = "";
            this.rechargePrice = '';
            this.withdrawPrice = '';
            this.bankSelect = "";

            clearInterval(this.timer); //清除定时器

            this.setPayPasswordDialog = false; //关闭设置密码对话框
            this.buyCouponDialog = false; //关闭购买点券对话框
            this.payQrDialog = false; //关闭支付二维码对话框
            this.rechargeDialog = false; //关闭充值对话框
            this.detailsDialog = false; //查看详情
            this.detailsDialog_balance = false; //查看详情
            this.detailsDialog_point = false; //查看详情
            this.detailsDialog_trust = false; //查看详情
            this.withdrawDialog = false; //提现对话框
        },

        //关闭二维码对话框
        closePayQrDialog() {
            this.payQrDialog = false;
            clearInterval(this.timer); //清除定时器
        },

        /**-------------------------- 通用 ------------------------- */

        //创建订单 - 通用方法
        createOrder(type = 1) {
            let _this = this;
            let pay_type = "";
            let pay_select_type = "";
            if (type == 1) {
                pay_select_type = _this.payTypeRadio; //购买点券
            } else {
                pay_select_type = _this.czRadio; //充值
            }

            switch (pay_select_type) {
                case 1: //余额支付
                    if (!_this.password) {
                        _this.$message.error("请输入支付密码");
                        return false;
                    }
                    _this.payType = "Y01";
                    break;

                case 2: //银行卡支付
                    if (_this.bankSelect == "") {
                        _this.$message.error("请选择银行卡");
                        return false;
                    }

                    if (!_this.password) {
                        _this.$message.error("请输入支付密码");
                        return false;
                    }
                    _this.payType = "U02";
                    break;

                case 3: //微信扫码
                    _this.payTypeText = "微信";
                    _this.payType = "W01";
                    break;

                case 4: //支付宝扫码
                    _this.payTypeText = "支付宝";
                    _this.payType = "A01";
                    break;

                default:
                    break;
            }

            _this.loading = true; //显示加载
            _this.post(
                "/order/order/add",
                {
                    cate: _this.cate, //1:充值；2:提现；3:购买点券；4:发送消息；5:查看消息；
                    title: _this.title, //'文字类型'
                    price: _this.price,
                    pay_type: _this.payType, //W01:微信扫码支付,A01:支付宝扫码支付,U02:银联JS支付,Y01:余额支付
                    remark: _this.remark
                },
                function (res) {
                    if (res.code == 200) {
                        _this.order_no = res.data.order_no; //获取订单号
                        _this.pay(); //支付
                        return false;
                    } else {
                        _this.$message.error("创建订单失败");
                    }
                    _this.loading = false;
                    return false;
                }
            );
        },

        //跳转到设置密码事件
        setPassword() {
            this.$router.push(
                "/user/validpassword?type=1&routerName=userSettingPassword&title=设置支付密码"
            );
        },

        //检查是否设置了支付密码
        isSetPayPassword() {
            if (this.userInfo.info.has_pay_password != 1) {
                this.setPayPasswordDialog = true; //打开设置密码对话框
                return false;
            }
            return true;
        },

        /**-------------------------- 添加银行卡 ------------------------- */

        //添加银行卡 - 获取绑定银行卡列表
        getBankList() {
            let _this = this;
            _this.post("/pay/pay/bandCard_list", {}, function (res) {
                if (res.code == 200) {
                    _this.bankList = res.data;
                }
            });
        },

        //添加银行卡 - 选择银行卡事件
        BankChange(val) {
            // console.log(val);
            // console.log(this.bankSelect);
        },

        //添加银行卡 - 跳转到银行卡绑定
        addBackBtn(flag) {
            this.$router.push("/user/wallet/add_bankcard");
        },

        /**-------------------------- 充值 ------------------------- */

        //充值 - 对公账户转账信息提交
        submit_zz() {
            let _this = this;

            if (!_this.user_name) {
                _this.$message.error("汇款人开户全称不能为空");
                return false;
            }

            if (!_this.user_no) {
                _this.$message.error("汇款开户账号不能为空");
                return false;
            }

            if (!_this.user_price) {
                _this.$message.error("汇款金额不能为空");
                return false;
            }

            if (!_this.user_bank) {
                _this.$message.error("汇款人开户行不能为空");
                return false;
            }

            _this.loading = true;

            //提交
            _this.post(
                "/pay/pay/recharge_offline",
                {
                    user_name: _this.user_name,
                    user_no: _this.user_no,
                    user_price: _this.user_price,
                    user_bank: _this.user_bank
                },
                function (res) {
                    if (res.code == 200) {
                        _this.dgzzDialog = false;
                        _this.loading = false;
                        _this.$message.success("提交成功");
                        // return false;
                    } else {
                        if (res.msg) {
                            _this.$message.error(res.msg);
                        } else {
                            _this.$message.error("提交失败");
                        }
                        _this.loading = false;
                    }
                }
            );
        },
        //充值 - 按钮事件
        recharge() {
            //判断是否设置了支付密码
            if (this.isSetPayPassword()) {
                //打开充值对话框
                this.rechargeDialog = true;
                this.czRadio = 3;
            }
        },
        //点充值【下一步】
        czNext() {
            this.rechargeDialog = false;
            this.dgzzDialog = true;
        },
        //充值 - 充值按钮事件
        rechargeBtn() {
            let _this = this;
            _this.price = _this.rechargePrice;
            _this.QRImgUrl = ""; //清空支付二维码，防止网络慢显示上一次的
            _this.cate = 1; //充值
            _this.title = "充值";

            if (_this.price <= 0) {
                _this.$message.error("请输入充值金额");
                return false;
            }
            _this.createOrder(2);
        },

        /**-------------------------- 提现 ------------------------- */

        //提现 - 提现radio事件
        changeTxRadio(val) {
            let _this = this;
            _this.password = ""; //清空密码
            // console.log(val);
        },
        //提现 - 提现按钮事件
        withdraw() {
            let _this = this;
            //判断是否设置了支付密码
            if (this.isSetPayPassword()) {
                if (_this.userInfo.info.attestation != 2) {
                    _this.$message.error(
                        "您还尚未认证，请认证后再进行提现操作"
                    );
                    return false;
                }
                this.withdrawDialog = true;
                this.txRadio = 2;

                //获取提现次数
                _this.post("/pay/pay/today_withdraw_times", {}, function (res) {
                    if (res.code == 200) {
                        _this.today_withdraw_times = res.data;
                    } else {
                        if (res.msg) {
                            _this.$message.error(res.msg);
                        } else {
                            _this.$message.error("提现失败，未知错误");
                        }
                    }
                    return false;
                }
                );
            }
        },

        //提现输入框 - 失去焦点事件
        withdraw_input_blur() {
            if (this.withdrawPrice == '') {
                this.tx_error_tip = '请输入提现金额';
                return false;
            }

            if (100 > parseFloat(this.withdrawPrice)) {
                this.tx_error_tip = '提现金额不足100元';
                return false;
            }

            if (parseFloat(this.userInfo.info.surplus) < parseFloat(this.withdrawPrice)) {
                this.tx_error_tip = '提现金额超出账户余额';
                return false;
            }
            this.tx_error_tip = '';
        },

        //提现 - 提现事件
        withdrawBtn() {
            let _this = this;
            let account = "";
            let type = "";
            let price = parseFloat(_this.withdrawPrice);

            switch (_this.txRadio) {
                case 2: //银行卡提现
                    account = _this.selected_tx_account = _this.bankSelect;
                    if (!account || account == "" || account == "undefind") {
                        _this.$message.error("请选择您提现用的银行卡");
                        return false;
                    }

                    // 根据银行卡id获取卡号
                    for (let index = 0; index < _this.bankList.length; index++) {
                        if (_this.bankSelect == _this.bankList[index].id) {
                            _this.show_account = _this.bankList[index].card_no;//提现确认银行卡账号
                        }
                    }

                    break;
                case 4: //支付宝提现
                    account = _this.selected_tx_account = _this.show_account = _this.withdrawAlipay;
                    if (!account || account == "" || account == "undefind") {
                        _this.$message.error("请输入支付宝提现账号");
                        return false;
                    }
                    break;
                default:
                    break;
            }

            if (!price || price == "" || price == "undefind" || price == 0) {
                _this.$message.error("请输入提现金额");
                return false;
            }

            if (price < 100) {
                _this.$message.error("提现金额不足100元");
                return false;
            }

            if (parseFloat(_this.userInfo.info.surplus) < price) {
                _this.$message.error("提现金额超出账户余额");
                return false;
            }

            if (_this.today_withdraw_times < 1) {
                _this.$message.error("您今天的提现次数不足");
                return false;
            }

            _this.tx_price1 = price;//提现金额
            _this.tx_price2 = price * 0.01;//手续费
            _this.tx_price3 = price * 0.99;//实际到账

            _this.withdrawDialog = false;
            _this.withdrawDialog_confirm = true;
        },

        close_withdrawDialog() {
            // this.withdrawDialog = false;
        },

        //提现申请 - 提交
        withdraw_submit() {
            let _this = this;
            if (!_this.password) {
                _this.$message.error("请输入支付密码");
                return false;
            }

            _this.post(
                "/pay/pay/withdraw",
                {
                    type: _this.txRadio == 2 ? 1 : 2,//如果是银行卡则为1，支付宝为2
                    account: _this.selected_tx_account,
                    price: _this.withdrawPrice,
                    password: _this.password
                }, function (res) {
                    if (res.code == 200) {
                        _this.withdrawDialog = false;
                        _this.withdrawDialog_confirm = false;
                        _this.$message.success(
                            "提现申请提交成功，等待管理员审核"
                        );
                        _this.getPayList(); //刷新资金明细
                        _this.ajaxUserInfo(); //刷新用户信息
                        setTimeout(() => {
                            _this.closeall();
                        }, 1000);
                    } else {
                        if (res.msg) {
                            _this.$message.error(res.msg);
                        } else {
                            _this.$message.error("提现失败，未知错误");
                        }
                    }
                    return false;
                }
            );
        },
        //提现 - 全部提现
        allWithdraw() {
            this.withdrawPrice = this.userInfo.info.surplus;
        },
        goCommission() {
            this.$router.push("/user/wallet/commission?key=2");
        },

        goHelp(id) {
            this.$router.push("/help/index/12?id=" + id);
        },
    }
};
</script>

<style lang="less" scroped>
@import "../../../styles/index.less";
.el-radio-group {
    display: block;
}
.el-radio-group .wbak-icon {
    vertical-align: none;
}
.bak-selt {
    width: 220px;
}
.withdrawalipay .el-input__inner {
    width: 300px;
    height: 28px;
    line-height: 26px;
}
.ticket-form .tick-radio {
    margin-top: 10px;
}
.el-range-editor.el-input__inner {
    height: 32px;
    line-height: 32px;
    padding: 0px 10px;
}
.typeTag {
    font-size: 20px !important;
}
.typeTag2 {
    font-size: 14px !important;
}
.el-tabs__nav-wrap::after {
    left: 20px;
    right: 20px;
    width: auto;
}

/deep/.bak-selt {
    .add-bank-btn {
        padding: 0 !important;
    }
}

.wallet-msg {
    .el-col-4 {
        text-align: right;
    }

    .el-col-20 {
        text-align: left;
    }
}

.wallet-popups {
    .wallet-bd {
        .el-col.el-col-20 {
            text-align: left;
            padding-left: 0;
        }
    }
}

/**对公账户转账 */
.dgzz-box {
    font-size: 14px;
    .title {
        font-weight: normal;
        font-size: 14px;
    }
    .desc {
        padding: 0 0 20px 0;
        border-bottom: 1px solid #ddd;
    }

    .dgzh {
        padding: 20px 0 20px 0;
        border-bottom: 1px solid #ddd;
        ul,
        li {
            margin: 0;
            padding: 0;
        }
        .list {
            margin-top: 10px;
            .item {
                height: 30px;
                line-height: 30px;
                .name,
                .value {
                    float: left;
                }
                .name {
                    color: #999;
                }
                .value {
                    color: #666;
                }
                .icon {
                    height: 30px;
                    line-height: 30px;
                    float: left;
                    margin-left: 10px;
                    width: 90px;
                    text-align: left;
                }
            }
        }
    }

    .zzxx {
        padding: 20px 0 20px 0;
        border-bottom: 1px solid #ddd;
        ul,
        li {
            margin: 0;
            padding: 0;
        }
        .list {
            margin-top: 10px;
            .item {
                height: 44px;
                line-height: 44px;
                clear: both;
                div {
                    display: inline-block;
                }
                .name {
                    width: 140px;
                    float: left;
                    color: #999;
                }
                .value {
                    width: 60%;
                    float: left;
                }
            }
        }
    }

    .sure {
        padding: 20px 0 20px 0;
        .btns {
            margin-top: 10px;
            text-align: center;
            .btn {
                height: 40px;
                width: 100px;
            }
        }
    }
}

.el-dialog__title {
    font-size: 16px;
    font-weight: normal;
}

.czbox {
    .yl_icon {
        width: 26px;
        height: 26px;
        float: left;
        margin-right: 6px;
        margin-top: 2px;
    }

    .el-dialog {
        border-radius: 6px;
        overflow: hidden;
    }

    .el-dialog__header:before {
        height: 24px;
        background: #587ddc;
    }

    .el-dialog__title {
        font-size: 16px;
        font-weight: normal;
        line-height: 30px;
    }

    .el-dialog__headerbtn {
        top: 3px;
        right: 6px;
        i {
            color: #fff;
        }
    }

    .topays-list {
        .ite-lab {
            margin-bottom: 10px;
            color: #555555;
        }
        .item1 {
            background: #f2f2f2;
            padding: 20px;
            border-radius: 6px;
        }

        .item2 {
            background: #f2f2f2;
            padding: 10px 20px;
            border-radius: 6px;
            .el-row {
                border-bottom: none;
            }
        }

        .item3 {
            margin-top: 10px;
        }
    }

    /deep/ .el-dialog--center {
        .el-dialog__body {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    .setbank-wrap {
        .el-row {
            .el-radio {
                padding: 4px;
                height: 39px;
                border: none;
                border-radius: 100px;
                .el-radio__input {
                    display: none;
                }
            }

            .el-radio__label {
                margin-left: -5px;
            }
        }
    }

    .is-checked {
        background-image: linear-gradient(#ced2e4, #dadeee);
        box-shadow: 0px 1px 1px #fff;
    }

    .bak-selt {
        .el-input__inner {
            background: none;
            // text-align:center;
            &::placeholder {
                color: #409eff;
            }
        }
    }
    .desc_box {
        border: 1px dashed #587ddc;
        background: #fff;
        border-radius: 6px;
        font-size: 14px;
        color: #999;
        margin-top: 30px;
        padding: 0px 30px 20px 30px;

        .titer {
            width: 100%;
            clear: both;
            font-weight: normal;
            height: 30px;
            margin-top: 20px;

            .icon {
                width: 20px;
                height: 20px;
                float: left;
                line-height: 24px;
                margin-top: 2px;
            }
            .text {
                float: left;
                line-height: 24px;
                margin-left: 6px;
            }
        }
        .con {
            ul {
                width: 100%;
                clear: both;
                li {
                    // text-indent: 2em;
                    line-height: 20px;
                    margin-bottom: 6px;
                }
            }
        }
    }

    .tx_center_box {
        height: 450px;
    }

    .el-dialog__body {
        padding-top: 0;
    }
}
</style>




